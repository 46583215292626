.vmv-service-footer {
	padding: 1rem 1.5rem;

	&__bottom-row {
		&__links {
			a {
				color: #378EF0;

				&:hover {
					color: #ffffff;
				}
			}
		}

		&__copyright {
			text-align: right;

			@media (max-width: 768px) {
				text-align: center;
			}

			&__text {

			}

			&__disclaimer {
				font-size: .75em;
				opacity: .75;
			}
		}
	}
}
