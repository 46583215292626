.vmv-service-tiles {
	margin: 1rem;
	scroll-margin-top: calc(105px + 1rem);

	@media (max-width: 768px) {
		margin: 1rem 0;
	}

	a {
		position: relative;
		text-decoration: none;
	}

	&__item {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		cursor: pointer;

		&:hover {
			.vmv-service-tiles {
				&__item {
					&__image {
						filter: brightness(100%) grayscale(.95);
						transform: scale(1.2);
					}
				}
			}
		}

		&__m-soon {
			@keyframes soon-movement {
				0% {
					background-position:
						0 0,
						0 0,
						0 .75rem;
				}

				100% {
					background-position:
						0 0,
						3rem 0,
						3rem .75rem;
				}
			}

			pointer-events: none;
			cursor: default;

			&:before {
				content: 'Скоро';

				position: absolute;
				left: 0;
				right: 0;
				bottom: .75rem;
				font-size: .6rem;
				line-height: 1rem;
				height: 1.5rem;
				font-weight: 600;
				letter-spacing: .1rem;
				color: #FFFFFF;
				background-image:
					linear-gradient(90deg, transparent 25%, #353535 40%, #353535 60%, transparent 75%),
					repeating-linear-gradient(60deg, transparent 0%, transparent 25%, fade(#353535, 40%) 25%, fade(#353535, 40%) 75%, transparent 75%, transparent 100%),
					repeating-linear-gradient(-60deg, transparent 0%, transparent 25%, fade(#353535, 40%) 25%, fade(#353535, 40%) 75%, transparent 75%, transparent 100%);
				background-position:
					0 0,
					0 0,
					0 .75rem;
				background-size:
					100%,
					3rem .75rem,
					3rem .75rem;
				background-repeat:
					no-repeat,
					repeat-x,
					repeat-x;
				animation: soon-movement 1s linear infinite;
				text-align: center;
				text-transform: uppercase;
				padding: .25rem .5rem;
				box-sizing: border-box;

				z-index: 1;
			}
		}

		&__image {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			transform: scale(1);
			filter: brightness(140%) grayscale(1);
			transition: transform .4s ease-out, filter .2s ease-in;
			z-index: -1;
		}

		&__title {
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			color: #ffffff;
			background-color: rgba(55, 142, 240, 0.75);
			backdrop-filter: blur(2px);
			box-shadow: 0 0 5px rgba(0, 0, 0, .25), 0 4px 10px rgba(0, 0, 0, .25);
			min-height: 4rem;
			width: 100%;
			padding: .5rem;
		}
	}
}
