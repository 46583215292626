.vmv-service-sections {

	&__item {
		scroll-margin-top: calc(105px + 1rem);
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: stretch;
		background-color: #353535;
		color: #FFFFFF;
		margin-top: 1rem;

		@media (max-width: 1100px) {
			flex-direction: column !important;
		}

		&__title {
			position: relative;
			flex: 1;
			overflow: hidden;
			padding-bottom: 4rem;
			box-sizing: border-box;

			&__background {
				position: absolute;
				top: -30px;
				left: -30px;
				right: -30px;
				bottom: -30px;
				background-size: cover;
				background-position: left center;
				background-repeat: no-repeat;
				background-clip: border-box;
				filter: grayscale(.95) blur(15px);
				z-index: 0;
			}

			&__fade {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				width: 100%;
				aspect-ratio: 1 / 1;
				background-image: linear-gradient(180deg, fade(#353535, 75%), transparent);
				z-index: 1;
			}

			&__text {
				position: relative;
				padding: .5rem 1rem;
				box-sizing: border-box;
				z-index: 2;
			}

			&__description {
				position: relative;
				font-size: 1rem;
				padding: 0 1rem 1rem;
				opacity: .75;
				z-index: 2;
			}

			&__button {
				position: absolute;
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				left: .5rem;
				right: .5rem;
				bottom: 1rem;
				z-index: 2;
			}
		}

		&__content {
			flex: 2;
			font-size: .9rem;
			padding: 1rem 4rem 2rem;
			box-sizing: border-box;

			&__button {
				display: none;
				margin-block-start: 2rem;

				@media (max-width: 1100px) {
					display: flex;
					flex-direction: row;
					justify-content: center;
					align-items: center;
				}
			}

			@media (max-width: 768px) {
				padding: 1rem 2rem;
			}

			blockquote {
				background: #ffffff;
				color: #378EF0;
				padding: 2rem 2rem 2rem 4rem;
				font-size: 1rem;
				font-weight: 600;

				@media (max-width: 768px) {
					padding: .5rem .5rem .5rem 1rem;
					margin-inline-start: 0;
					margin-inline-end: 0;
				}
			}
		}

		&:nth-child(2n + 1) {
			background-color: #378EF0;
			flex-direction: row-reverse;

			.vmv-service-sections {
				&__item {
					&__title {
						&__background {
							background-position: right center;
						}

						&__fade {
							background-image: linear-gradient(180deg, fade(#378EF0, 57%), transparent);
						}
					}
				}
			}
		}
	}
}
