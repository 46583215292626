@charset "UTF-8";

@import (css) url('https://fonts.googleapis.com/css2?family=Staatliches&display=block');

:root {
	--full-address-display: inline-block;

	@media (max-width: 768px) {
		--full-address-display: none;
	}
}

html,
body {
	width: 100%;
	height: 100%;
	min-height: 100%;
	scroll-behavior: smooth;
}

a {
	color: #378EF0;
	transition: color .2s linear;

	&:hover {
		color: #ffffff;
	}
}

#vmv-service {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
}

.vmv-service {
	@keyframes entrance {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}

	width: 100%;
	min-height: 100%;
	animation: entrance .5s linear;
}
