.vmv-service-contacts {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: stretch;
	margin-top: 1rem;
	scroll-margin-top: calc(105px + 1rem);

	@media (max-width: 1100px) {
		flex-direction: column-reverse !important;
		align-items: center;

		.vmv-service-contacts {
			&__map {
				max-width: none;
			}

			&__data {
				padding-bottom: 3rem;

				&__title,
				&__subtitle,
				&__variant {
					width: 100%;
					text-align: center;
				}
			}
		}
	}

	&__map,
	&__data {
		flex: 1;
		width: 100%;
		box-sizing: border-box;
	}

	&__map {
		position: relative;
		max-width: 650px;

		&__link {
			display: block;
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
			width: 100%;
			aspect-ratio: 1 / 1;
			filter: grayscale(1);
		}

		&__point {
			@keyframes pulse {
				0%, 100% {
					transform: scale(1);
					opacity: .5;
				}

				50% {
					transform: scale(.75);
					opacity: .25;
				}
			}

			position: absolute;
			top: 50%;
			left: 50%;
			width: 1rem;
			height: 1rem;
			margin: -.5rem 0 0 -.5rem;
			border-radius: .5rem;
			background-color: #378EF0;

			&:before {
				content: '';

				position: absolute;
				top: 50%;
				left: 50%;
				width: 2rem;
				height: 2rem;
				margin: -1rem 0 0 -1rem;
				border-radius: 1rem;
				background-color: #378EF0;
				opacity: .5;
				animation: pulse 1s linear infinite;
			}
		}
	}

	&__data {
		padding: 1rem 2rem;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-end;

		&__title,
		&__subtitle,
		&__variant {
			width: 100%;
			text-align: right;
		}

		&__title {
			margin-block-start: 0;
			margin-block-end: 0;
		}

		&__subtitle {
			margin-block-start: .5rem;
		}
	}
}
